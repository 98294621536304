@import url('https://fonts.googleapis.com/css?family=Neucha');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Kalam');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html, body {
  /* IE 10-11 didn't like using min-height */
  height: 100vh;
  height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  --svgreen: #78C73D;
  --svred: #DC3545;
;

}

button {
  margin-left:auto;
  margin-bottom: 5px; /*for some reason need this to center align with logo text */
  border: 1px solid grey;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

button:hover {
  color:gray;
}

h2 {
  text-transform: capitalize;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


/* GENERAL ***********************************/
.link {
  color: var(--svgreen);
  font-weight: 700;
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
}

.sv-button {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
}

.sv-button:hover {
  color:gray;
}

.error-warning {
  color:gray;
  font-size:14px;
  margin: 10px auto;
}

.error-error {
  color:red;
  font-size:14px;
  margin: 10px auto;
}

/* LANDING ***********************************/

.landing-outer {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.landing-header {
  display: flex;
  padding: 20px;
}

.landing-header-link {
  font-size: 16px;
  text-transform: uppercase;
  padding: 0px 10px;
  cursor: pointer;
  text-decoration: none;
  color: dimgrey;
}

.landing-header-link:hover {
  color:grey;
}

.landing-title {
  font-family: Neucha; 
  font-size: 56px; 
  color: #78C73D;
  margin: 0 auto;
  padding: 60px 0;
  white-space: nowrap;
  text-align: center;
}

.landing-brief {
  font-family: Kalam; 
  font-size: 48px; 
  color: black;
  min-width: 275px;
  width: 40%;
  padding: 40px 0;
  text-align: center;
  margin: 0 auto;
}

.landing-button-group {
  width: 270px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.landing-box {
  margin:auto;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.landing-invite-code-outer {
  border: 1px solid var(--svgreen);
  border-radius: 10px;
  min-width: 250px;
  width: 25%;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
}

.landing-invite-code {
  border:none;
  border-right: 1px solid var(--svgreen);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
  flex: 70%;
  font-size: 14px;
  color:dimgrey;
}

.landing-submit-invite {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: azure;
  text-align: center;
  flex: 30%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color:dimgrey;
}

.landing-submit-invite:hover {
  background-color: var(--svgreen);
  color: white;
}


.landing-beta {
  margin: 40px auto;
  font-size: 14px;
  padding: 0 40px;
  overflow-wrap: break-word;
  text-align: center;
}

.landing-beta-waitlist {
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
}

.landing-beta-waitlist:hover {
  color:grey;
}






/* LOGIN ***********************************/

.login-form {
  margin: 0 20px;
  width: 300px;
}

.login-button-group {
  margin-bottom: 20px;
}

.login-button {
  margin: 5px 0 !important;
  text-align: center;
  padding: 10px !important;
}

.login-or {
  height: 10px;
  border-bottom: 1px solid lightgrey; 
  text-align: center;
  margin: 30px 35px;
  display: hidden;
}

.login-or-text {
  background-color: white; 
  padding: 0 10px;
  border: 1px solid lightgrey; 
  display: hidden;
  color: dimgrey;
}

.sign-in-google {
  background-color: white;
  color: dimgray;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-google-svg {
  height: 18px;
  margin-right: 20px;
}

.login-message {
  color: dimgray;
  margin: 10px auto;
  font-size: 12px;
  overflow-wrap: break-word;
  text-align: center;
}


/* ADD REST ***********************************/
.add-rest-welcome {
  font-family: Kalam; 
  font-size: 48px; 
  color: black;
  min-width: 275px;
  padding: 40px 0;
  text-align: center;
  margin: 0 auto;
}
.add-rest-message {
  margin: 20px auto;
  font-size: 14px;
  padding: 0 35px;
  overflow-wrap: break-word;
  text-align: center;
  color: dimgray;
}

.add-rest-input {
  border:none;
  border-right: 1px solid var(--svgreen);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 0;
  text-align: center;
  flex: 70%;
  font-size: 14px;
  color:dimgrey;
}


/* HEADER **********************************************/

.header {
  display:flex;
  align-items: center;
  border-bottom: 1px solid var(--svgreen);
  padding: 15px 0;
}

.menu-header {
  padding: 20px;
}

.app-title-outer {
  margin-left: 20px;
}

.app-title {
  font-family: Neucha; 
  font-size: 24px; 
  color: #78C73D;
  padding-top: 5px;
}

.menu-container {
  display: flex;
  flex-direction:column;
  width: 40px;
  height: 32px;
  cursor: pointer;
  margin-left:20px;
}

.left-spoon-container {
  position: relative;
  height: 10px;
  margin-bottom: 4px;
}
.left-spoon{
    background-color: var(--svgreen);
    width: 18px;
    height: 10px;
    border-radius: 50%;
    position:absolute;
    top:0px;
    left:0px;
}
.left-spoon-handle{
    background-color: var(--svgreen);
    width: 36px;
    height: 4px;
    position:absolute;
    top:3px;
    left:4px;
}
.middle-handle-container {
  position: relative;
  height: 4px;
  margin-bottom:7px;
}
.middle-spoon-handle{
    background-color: var(--svgreen);
    width: 40px;
    height: 4px;
    position:absolute;
    top:0px;
    left:0px;
}
.right-spoon-container {
  position: relative;
  height: 10px;
}
.right-spoon{
    background-color: var(--svgreen);
    width: 18px;
    height: 10px;
    border-radius: 50%;
    position:absolute;
    top:-3px;
    left:22px;
}
.right-spoon-handle{
    background-color: var(--svgreen);
    width: 36px;
    height: 4px;
    position:absolute;
    top:0px;
    left:0px;
}

.help {
  color:darkorange;
  margin-left: auto;
  font-weight: 500;
  cursor: pointer;
}

.help:hover {
  color:orange;
}

.add-vendor-button {
  margin-left:15px;
  margin-right: 20px;
  border: 1px solid darkorange;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none;
  color:darkorange;
}

.no-help {
  margin-left:auto !important;
}

.add-vendor-button:hover {
  color:orange;
  border: 1px solid orange;
}


/* BACK NAVIGATION ******************************/

.back-header {
  display:flex;
  align-items: center;
  margin: 20px;
}

.back-icon {
  cursor: pointer;
  margin-right: 10px;
}

.back-icon:hover {
  color:gray;
}


/* VENDORS ********************************/
/* VENDORS / HELP ********************************/

.v-no-vendors {
  margin:20px;
  font-size:14px;
  display:flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
}

.spoons {
  width: 100%;
  margin-top: 40px;
}

.new-rest-cancel {
  width: 40px !important;
  height: 40px !important;
  color: var(--svgreen);
  margin-left: auto;
  padding: 10px 10px 0 0;
  cursor: pointer;
}

.new-rest-cancel:hover {
  color: green;
}

.new-rest-header {
  font-family: Kalam; 
  font-size: 22px; 
  color: black;
  padding: 20px 10px 0 10px;
  text-align: center;
  margin: 0 auto;
}

.new-rest-example {
  text-align: center;
  font-size: 16px;
  color: dimgrey;
  padding: 5px;
  display: flex;
  align-items: center;
  margin: auto;
}

.new-rest-orange {
  color: darkorange;
  padding: 3px;
  font-weight: bold;
}

.new-rest-green {
  color: var(--svgreen);
  padding: 3px;
  font-weight: bold;
}

.new-rest-italic {
  font-weight: bold;
  padding: 3px;
}

.new-rest-text {
  text-align: center;
  font-size: 12px;
  color: dimgrey;
  padding: 0 10px;
}

.new-rest-again-text {
  text-align: center;
  font-size: 12px;
  color: dimgrey;
  padding: 0 10px;
  margin-bottom: 20px;
}

/* VENDORS / SEARCH ********************************/

.search-container {
  margin:20px 20px 0 20px;
  display: flex;
}
.my-search {
  padding: 10px;
  flex: 1;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 16px;
}

/* remove outline area of an input upon focus */
textarea:focus, input:focus{
    outline: none;
}

.my-search::-webkit-search-cancel-button {
  position:relative;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}

/* VENDORS / VCARD ********************************/


.v-card {
  display:flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 
  margin: 10px 20px 10px 20px;
}
.v-card-on-list {
  border: 1px solid darkorange !important;
}

.v-card-section {
  border-bottom: 1px solid lightgrey;
  padding: 15px 20px;
}

.v-card-section:last-child {
  border-bottom: none;
}

.v-card-group-title {
  font-family: Kalam; 
  font-size: 24px; 
  color: black;
  padding-top: 20px;
  text-align: center;
  margin: 0 auto;
}

.v-card-group-title-on-list {
  color: darkorange !important;
}

.v-card-section-header {
  display:flex;
}

.v-card-row {
  display: flex;
  align-items: center;
  color: gray;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  padding-top: 20px;
  flex-grow:1;
}

.v-card-collapsed-section-header {
  display:flex;
  align-items: center;
}

.collapse {
  margin-left: auto;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.collapse:hover {
  color:grey;
}

.v-card-action {
  margin-left:7px;
  cursor: pointer;
  font-weight:bold;
  font-size:12px;
  font-family: Roboto;
  display: flex;
  align-items: center;
  color: green;
  white-space: nowrap;
}

.v-card-action:hover {
  color:grey;
}

.v-card-icon {
  cursor: pointer;
  width: 15px !important;
  height: 15px !important;
}

.v-card-icon:hover {
  color:gray;
}

/* VENDORS / VCARD / HEADER ********************************/


.v-card-header {
  display:flex;
  align-items: center;
}

.v-card-header-title-outer {
  display: flex;
  flex-direction: column;
}

.v-card-header-title-inner {
  display: flex;
}

.v-card-list-action {
  font-size: 12px;
  color: darkorange;
  font-weight: bold;
  cursor: pointer;
}

.v-card-v-title {
  font-size: 18px;
  font-weight:bold;
  font-family: Roboto;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 40px;
  padding-right: 10px;
}

/*
.v-card-tag {
  background-color: lightgrey;
  border-radius: 4px;
  padding: 3px 20px;
  margin-right: 5px;
  font-size: 10px;
  text-transform: uppercase;
}

.v-card-tag-private {
  background-color: azure !important;
  margin-left: 5px;
  color:green;
  border: 1px solid green;
}
*/
.v-card-private {
  border: 1px solid grey;
  color: dimgrey;
  border-radius: 10px;
  padding: 5px;
  font-size: 10px;
}


/*
.v-card-store-icon {
  height:25px !important;
  width:25px !important;
  color: darkorange;
  margin-left: 5px;
}
*/

.v-card-star-icon {
  height:25px !important;
  width:25px !important;
  cursor: pointer;
  color: darkorange;
  margin-right: 5px;
}

.v-card-star-icon:hover {
  color:orange;
}

.v-card-star-icon-filled {
  background-color: darkorange;
}

.v-card-header-row {
  display: flex;
  flex-direction: column;
  color: gray;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  align-items: flex-start;
  overflow: hidden;
  flex-grow:1;
}

.v-card-header-service {
  font-size: 16px;
  border-bottom: 2px solid var(--svgreen);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  color: dimgrey;
  text-transform: capitalize;
}

.v-card-header-label {
  font-size: 12px;
  color:lightslategray;
  min-width: 90px;
}

.v-card-header-value {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  color: dimgrey;
}

/* VENDORS / VCARD / RATING ********************************/

.vote {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-left: 40px;
}

.vote-arrow {
  cursor: pointer;
  color: grey;
  width: 20px !important;
  height: 20px !important;
  margin-top: 1px;
}

.vote-arrow:hover {
  color: dimgrey;
}

.vote-counter {
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.vote-great {
  color: var(--svgreen);
}

.vote-poor {
  color: var(--svred);
}

.vote-rec-label {
  font-size: 13px !important;
}

/* VENDORS / VCARD / BASIC DETAILS ********************************/


/* VENDORS / VCARD / CONTACT DETAILS ********************************/

.v-card-contact-row {
  display: flex;
  color: gray;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  padding-top: 20px;
  flex-grow:1;
}

.v-contact-actions {
  display: flex;
  justify-content: center;
  margin-right: 20px;
}

.v-contact-name-grouping {
  display: flex;
}



/* VENDORS / VCARD / ... ********************************/






.v-card-edit {
  cursor: pointer;
  font-weight:bold;
  font-size:14px;
  font-family: Roboto;
  color: green;
  margin-left: auto;
  padding-left: 25px;

}

.v-card-edit:hover {
  color:grey;
}

/*
.v-card-rate {
  margin-left:auto;
  padding-right: 5px;
  padding-left: 25px;
  cursor: pointer;
  font-weight:bold;
  font-size:14px;
  font-family: Roboto;
  color: green;
}

.v-card-rate:hover {
  color:grey;
}
*/



.v-card-row-service {
  font-size: 16px;
  text-decoration: solid underline var(--svgreen) 2px;
  text-decoration-skip-ink: none;
  max-width: 90%;
  text-transform: capitalize;
}

.v-card-sub-header {
  margin-top: 15px;
}

.v-card-v-details {
  font-size: 14px;
  padding: 10px 0;
}

.v-card-main {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.v-card-service {
  border-bottom: 2px solid var(--svgreen);
  font-size: 16px;
  color: dimgray;
  margin-bottom:10px;
  display: flex;
  flex-shrink: 1;
}



.v-card-rating {
  display:flex;
  margin: 10px 0;
  font-family: Roboto,arial,sans-serif;
}

.rating-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rating-edit {
  font-size: 12px;
  cursor: pointer;
  display: flex;
  margin: 2px auto;
  padding-right: 15px;
  color: green;
  font-weight: bold;
}

.rating-rating {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 5px 20px;
  margin-right: 15px;
  min-width: 90px;
  text-align: center;
}

.rating-column {
  display:flex;
  flex-direction: column;
  margin: 2px 0;
  align-items: center;
}

.rating-rest {
  text-transform: capitalize;
  margin-right: 10px;
  max-width: 130px;
  min-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.58;
  color: #202124;
}





.rating-great {
    background-color: var(--svgreen);
    color:white;
}

.rating-poor {
    background-color: #DC3545;
    color:white;
}

.rating-ok {
    background-color: dimgrey;
    color:white;
}

.rating-time-since {
  margin-right: 10px;
  max-width: 130px;
  min-width: 130px;
  font-size: 13px;
  color:#70757a;
}

.rating-note {
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 18px;
  color: #202124;
}

.v-card-history {
  color:grey;
  font-size:12px;
  font-style: italic;
}
.v-card-history-op {
  font-weight: 600;
}


/* RATE VENDOR  */

.rating-radio-block {
  display:flex;
  flex-direction: column;
  margin-top: 5px;
}

.rating-radio-item {
  display:flex;
  align-items: center;
  margin: 3px 0;
}

.rating-radio {
  margin-right:10px;
}

.rating-radio-label {
  text-transform: uppercase;
  margin-right: 10px;
  min-width: 90px;
  color:gray;
  font-size: 12px;
}

.rating-radio-expl {
  font-size: 14px;
}

.rating-radio-great {
    color: var(--svgreen) !important;
}

.rating-radio-poor {
    color: #DC3545 !important;
}

.rating-radio-ok {
    color: dimgrey !important;
}


/* VIEW VENDOR DETAILS *****************************/

.view-v-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid lightgrey;
  margin: 20px;
}

.view-v-card-header {
  display:flex;
  align-items: center;
  padding: 20px;
}

.view-v-card-title {
  font-size: 18px;
  font-weight:bold;
  font-family: Roboto;
}

.view-v-card-action {
  margin-left:auto;
  cursor: pointer;
  font-weight:bold;
  font-size:14px;
  font-family: Roboto;
  display: flex;
  align-items: center;
  color: green;
  white-space: nowrap;
}

.view-v-card-action:hover {
  color:grey;
}

.view-v-card-instruction {
  padding: 0 20px 20px 20px;
  font-size: 12px;
}

/*  CONTACTS */

.view-v-contacts-outer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgrey;
  padding: 20px 0;
}

.view-v-contacts-outer:last-child {
  border-bottom: none;
  border-radius: 10px;
}

.view-v-contact-name {
  text-transform: capitalize;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.58;
  color: #202124;
}

/*
.view-v-contact-name {
  margin-right: 10px;
  max-width: 130px;
  min-width: 130px;
  font-size: 13px;
  color:#70757a;
  line-height: 1.58;
}
*/

.view-v-contact-contact {
  font-size: 13px;
  line-height: 18px;
  color: #202124;
}

.view-v-contact-actions {
  display: flex;
  margin-top:25px;
  justify-content: center;
}

.view-v-action {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}

.view-v-action .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--svgreen);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #78C73D;
}

.view-v-action .selected {
  color:white !important;
  background-color: var(--svgreen) !important;
}

.view-v-action:hover .circle {
  color: green;
  background-color: azure;
}

.view-v-action:hover .selected {
  color:green !important;
}

.view-v-action .title {
  text-align: center;
  font-size: 13px;
  color: #78C73D;
  font-weight: bold;
  padding: 10px;
}


/*  GENERAL */

.view-v-details-row {
  display: flex;
  border-bottom: 1px solid lightgrey;
  color: gray;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  align-items: center;
  padding: 20px;
}

.view-v-details-row:last-child {
  border-bottom: none;
  border-radius: 10px;
}

.view-v-vendors-row {
  display: flex;
  border-bottom: 1px solid lightgrey;
  color: gray;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  cursor: pointer;
  padding: 20px;
  align-items: center;
}

.view-v-vendors-row:hover {
  background-color:mintcream;
}

.view-v-vendors-row:last-child {
  border-bottom: none;
  border-radius: 10px;
}

.view-v-vendors-tags {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 10px;
}

.view-v-vendors-tags-private {
  background-color: azure !important;
  color:green;
  border: 1px solid green;
}

.view-v-vendors-row-edit {
  margin-left:auto;
}


.view-v-details-row-left {
  /*min-width: 280px;*/
  display: flex;
  flex-direction: column;
  overflow:hidden;
}

.view-v-details-row-middle {
  /*min-width: 245px;*/
}

.view-v-details-row-left-action {
  margin-right: 10px;
}

.view-v-details-row-label {
  font-size: 12px;
  color:lightslategray;
  min-width: 90px;
}

.view-v-details-row-value {
  font-size: 16px;
}

.view-v-details-row-label-rest {
  font-size: 16px;
  color:lightslategray;
}

.view-v-details-row-value-rest {
  font-size: 12px;
  color: green;
}

.view-v-details-row-history {
  font-size: 12px;
}

.view-v-icon {
  cursor: pointer;
}

.view-v-icon:hover {
  color:gray;
}

.view-v-history {
  text-align: right;
}

.view-v-ratings-section {
  margin: 0 20px 20px 20px;
}


/* VENDOR FORMS *******************************************/

.vendor-form {
  margin: 0 20px;
}

.form-label-group {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}

.form-label {
  font-size: 12px;
  color:lightslategray;
}

.form-label-admin {
  font-size: 12px;
  color:white;
  background-color: red;
}

.form-add {
  font-size: 12px;
  color:green;
  margin-left:auto;
  cursor: pointer;
}

.service-add {
  color:green;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.form-privacy-label {
  font-size: 14px;
  color:dimgrey;
  margin-left: 15px;
}

.form-input {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 10px;
  margin-top: 3px;
}

.MuiChip-label {
  text-transform: capitalize;
}

.MuiAutocomplete-option {
  text-transform: capitalize;
}

.rate-form-vote-group {
  margin-top: 10px;
}
.rate-form-thumb-space {
  margin-right: 10px;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.form-button-group {
  display: flex;
  margin: 20px 0;
  align-items: flex-start;
}

.form-button {
  border: 1px solid var(--svgreen);
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none;
  color:#78C73D;
  margin-right:10px;
}

.primary {
  background-color: var(--svgreen);
  color: white;
}

.form-button:hover {
  color:green;
  /*background-color: azure;*/
}

.primary:hover {
  color: white !important;
  background-color: var(--svgreen) !important;
}



/* RESPONSIVE ******************************/
/* RESPONSIVE ******************************/
/* RESPONSIVE ******************************/
/* RESPONSIVE ******************************/
/* RESPONSIVE ******************************/


@media screen and (max-width: 2600px){
  .landing-brief {
    font-size: 48px;
    width: 60%; 
  }
  .add-rest-welcome {
    font-size: 48px; 
  }
  .search-container {
    margin:20px 30%;
  }
  .v-card {
    margin: 20px 30%;
  }
  .v-card-header-row {
    flex-direction: row;
    align-items: center;
  }
  .v-card-row {
    flex-direction: row;
    align-items: center;
  }
  .view-v-card {
    margin: 20px 30%;
  }
  .view-v-action {
    margin: 0 20px;;
  }
  .v-no-vendors {
    margin:20px 30%;
  }
  .vendor-form {
    margin: 20px 10%;
  }
  .back-header {
    margin: 20px 30%;
    display:flex;
    align-items: center;
  }
  .view-v-icon {
    flex: 10%;
  }
}

/* two rows */
@media screen and (max-width: 1125px){
  .landing-brief {
    font-size: 42px; 
    width: 80%;
  }
  .add-rest-welcome {
    font-size: 48px; 
  }
  .search-container {
    margin:20px 20%;
  }
  .v-card {
    margin: 20px 20%;
  }
  .v-card-header-row {
    flex-direction: row;
    align-items: center;
  }
  .v-card-row {
    flex-direction: row;
    align-items: center;
  }
  .view-v-card {
    margin: 20px 20%;
  }
  .view-v-action {
    margin: 0 20px;
  }
  .v-no-vendors {
    margin:20px 20%;
  }
  .vendor-form {
    margin: 0 20%;
  }
  .back-header {
    margin: 20px 20%;
  }
  .view-v-icon {
    flex: 20%;
  }
}

@media screen and (max-width: 750px){
  .landing-brief {
    font-size: 18px; 
  }
  .add-rest-welcome {
    font-size: 24px; 
  }
  .search-container {
    margin:20px 20px 0 20px;
  }
  .v-card {
    margin: 20px;
  }
  .v-card-header-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .v-card-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .view-v-card {
    margin: 20px;
  }
  .view-v-action {
    margin: 0 10px;;
  }
  .v-no-vendors {
    margin:20px;
  }
  .vendor-form {
    margin: 0 20px;
  }
  .back-header {
    margin: 20px;
  }
  .view-v-icon {
    flex: 20%;
  }
}



/*
.rest-card {
  border: 1px solid #222222;
  border-radius: 5px;
  width: 350px;
  font-size: 16px;
  overflow: hidden;
  margin: 5px 0px;
  padding: 5px;
  display:flex;
  align-items: center;
  cursor: pointer;
}

.rest-card-icon {
  padding-right: 5px;
}

.rest-card:hover {
  color:gray;
}

*/



/*



.toggle {
  display:flex;
  align-items: center;
  padding-bottom: 15px;
}

.private-expl {
  font-size: 12px;
  align-items: center;
  display:flex;
  margin-left:15px;
}

.vendor-card-outer {
  max-width: 350px;
  margin:5px 0px;
  padding:0px;
  display:flex;
  flex-direction: column;
}
.vendor-card-private {
  border: 1px solid #222222;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 
  background-color: #B3CBF6;
  border-radius: 10px;
  width: 338px;
  font-size: 16px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 1px;
  padding: 5px;
  display:flex;
  flex-direction: column;
}

.vendor-card-community {
  border: 1px solid #222222;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 
  background-color: #FDF288;
  border-radius: 10px;
  width: 338px;
  font-size: 16px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 1px;
  padding: 5px;
  display:flex;
  flex-direction: column;
}

.vendor-card-top {
  display:flex;
  align-items: center;
  padding-top: 15px;
}

.vendor-card-bottom {
  display: flex;
  justify-content: right;
  padding-top:5px;
}


.vendor-controls {
  display:flex;
  flex-direction: column;
}
.vendor-card-middle {
  display:flex;
  flex-direction: column;
  flex-grow:1;
  padding-left: 15px;
  border-right: 1px dashed grey;
  border-left: 1px dashed grey;
}

.vendor-card-right {
  display:flex;
  flex-direction: column;
  padding-left:10px;
}

.vendor-card-icon {
  padding-right: 10px;
  cursor: pointer;
}

.vendor-card-icon:hover {
  color:gray;
}

.vendor-card-phone {
  padding-left: 5px;
}


.vendor-lock-icon {
  height:15px !important;
  width:15px !important;
  cursor: pointer;
  padding-right:5px;
}

.vendor-lock-icon:hover {
  color:gray;
}

.vendor-actions-icon {
  height:15px !important;
  width:15px !important;
  cursor: pointer;
}

.vendor-actions-icon:hover {
  color:gray;
}

.contacts-icon {
  cursor: pointer;
}

.contacts-icon:hover {
  color:gray;
}

.vendor-card-header {
  display:flex;
}

.vendor-card-name-outer {
  display: flex;
  flex-grow:1;
  align-items: center;  
}

.vendor-card-name {
  display: flex;
  align-items: center;
  padding:5px;
}

.vendor-card-cat {
  display:flex;
  align-items: center;
  border-bottom: 1px dashed grey;
}

.vendor-card-cat-item {
  display:flex;
  padding: 5px;
}

.vendor-contact-info {
  max-width:238px;
  overflow: hidden;
}

.vendor-contact-more {
  max-width:270px;
  overflow: hidden;
  display: flex;
  margin:auto;
  padding-top: 5px;

}
/*/








